<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">电商产业详情</span>
          </div>
          <div class="mod2-b">
            <div class="col">
              <div class="tuiul">
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/s1.png" alt="" />
                  <div class="u_text">
                    全部 <span class="visitors">(访客数)</span>
                    <div class="tex_box"><span class="nub">115</span>人</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/s2.png" alt="" />
                  <div class="u_text">
                    PC <span class="visitors">(访客数)</span>
                    <div class="tex_box"><span class="nub">10</span>人</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/s3.png" alt="" />
                  <div class="u_text">
                    H5 <span class="visitors">(访客数)</span>
                    <div class="tex_box ffa"><span class="nub">3</span>人</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/s4.png" alt="" />
                  <div class="u_text">
                    微信小程序 <span class="visitors">(访客数)</span>
                    <div class="tex_box"><span class="nub">104</span>人</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/s5.png" alt="" />
                  <div class="u_text">
                    安卓 <span class="visitors">(访客数)</span>
                    <div class="tex_box"><span class="nub">0</span>人</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/s6.png" alt="" />
                  <div class="u_text">
                    IOS <span class="visitors">(访客数)</span>
                    <div class="tex_box ffa"><span class="nub">0</span>人</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mod1-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">访客数TOP</span>
              </div>

              <ul class="mo_ul">
                <vue-seamless-scroll
                  :data="fankeTop"
                  :class-option="scrollOption"
                  class="scrollb"
                >
                  <li v-for="item in fankeTop" :key="item.id">
                    <div class="u_lef">
                      <span>NO.{{ item.id }}</span>
                      <img :src="item.img" alt="" />
                    </div>
                    <div class="u_cen">
                      {{ item.name }}
                    </div>
                    <div class="u_rig">访客数 : {{ item.visitor }}</div>
                  </li>
                </vue-seamless-scroll>
              </ul>

              <div class="b-smtit">
                <span class="bt">支付金额TOP</span>
              </div>
              <div class="bott_ul">
                <ul>
                  <li>
                    <div class="le_l">排名</div>
                    <div class="le_r">商品名称</div>
                  </li>
                  <li>
                    <div class="le_l">1</div>
                    <div class="le_r">朱兰花茶</div>
                  </li>
                  <li>
                    <div class="le_l">2</div>
                    <div class="le_r">山亲花油</div>
                  </li>
                  <li>
                    <div class="le_l">3</div>
                    <div class="le_r">瓜州构杞</div>
                  </li>
                </ul>
                <div class="charta" ref="chart6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen">
          <div class="mod2-bot">
            <ul class="bot_ul">
              <li>
                <div class="b-smtit">
                  <span class="bt">订单销售实况</span>
                </div>
                <div class="jebox">
                  <span class="text">金额</span>

                  <div class="rig_box">
                    <div class="miaso">
                      <span>亿</span>
                      <span>千万</span>
                      <span>百万</span>
                      <span>十万</span>
                      <span>万</span>
                      <span>千</span>
                    </div>
                    <animate-number
                      class="spans"
                      from="0"
                      to="210001286"
                      duration="5000"
                    ></animate-number>
                  </div>
                </div>
              </li>
              <li>
                <div class="b-smtit">
                  <span class="bt">订单销售实况</span>
                </div>
                <div class="cen_box">
                  <span class="box_lef">累计订单数量</span>
                  <span class="box_rig">10000件</span>
                </div>
              </li>
              <li>
                <div class="b-smtit">
                  <span class="bt">订单销售实况</span>
                </div>
                <div class="cen_box">
                  <span class="box_lef">订单达成率</span>
                  <span class="box_rig">65%</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="con-r">
        <div class="mod4 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">会员情况统计</span>
          </div>
          <div class="mod4-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">会员统计</span>
              </div>
              <ul class="bar-list">
                <li
                  class="row"
                  v-for="(item, index) in chucunshuliangList"
                  :key="index"
                >
                  <div class="smbox">
                    <div class="font">
                      <span>{{ item.name }}</span>
                      <span>{{ item.num }}个</span>
                    </div>
                    <div class="progress">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                  <div class="smrig">
                    <img src="https://home/ResImg/shangchengimg/jiantotop.png" alt="" />
                    <span>160%</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">累计会员数</span>
              </div>
              <div class="chart" ref="chart5"></div>
            </div>
          </div>
        </div>
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">电商产业详情</span>
          </div>
          <div class="b-smtit">
            <span class="bt">会员统计</span>
          </div>
          <div class="mod2-b mam2">
            <div class="col">
              <div class="tuiul">
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o1.png" alt="" />
                  <div class="u_text">
                    茶类
                    <div class="tex_box"><span class="nub">125</span>万元</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o2.png" alt="" />
                  <div class="u_text">
                    水果
                    <div class="tex_box ffa">
                      <span class="nub">500</span>万元
                    </div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o3.png" alt="" />
                  <div class="u_text">
                    蔬菜
                    <div class="tex_box"><span class="nub">300</span>万元</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o4.png" alt="" />
                  <div class="u_text">
                    特产
                    <div class="tex_box ffa">
                      <span class="nub">500</span>万元
                    </div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o5.png" alt="" />
                  <div class="u_text">
                    生鲜
                    <div class="tex_box"><span class="nub">200</span>万元</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o6.png" alt="" />
                  <div class="u_text">
                    零食
                    <div class="tex_box ffa">
                      <span class="nub">500</span>万元
                    </div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o7.png" alt="" />
                  <div class="u_text">
                    肉类
                    <div class="tex_box"><span class="nub">100</span>万元</div>
                  </div>
                </div>
                <div class="u_list">
                  <img src="https://home/ResImg/shangchengimg/o8.png" alt="" />
                  <div class="u_text">
                    其他
                    <div class="tex_box ffa">
                      <span class="nub">500</span>万元
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mod5 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">仓储概况</span>
          </div>
          <div class="mod5-sl">
            <div class="chart" ref="chart7"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      randomNum: 0,
      swiper: [],
      end: 2,
      fankeTop: [
        {
          id: 1,
          name: " 金钱莲",
          visitor: "16",
          img: "https://home/ResImg/shangchengimg/ul1.png",
        },
        {
          id: 2,
          name: " 朱兰花茶",
          visitor: "15",
          img: "https://home/ResImg/shangchengimg/ul2.png",
        },
        {
          id: 3,
          name: " 农家酸菜江西赣州寻乌地方特产",
          visitor: "10",
          ranking: 3,
          img: "https://home/ResImg/shangchengimg/ul3.png",
        },
        {
          id: 4,
          name: " 风州构杞",
          visitor: "8",
          img: "https://home/ResImg/shangchengimg/ul4.png",
        },
        {
          id: 5,
          name: " 千岁好山茶油 有机油燕籽油 压榨",
          visitor: "17",
          img: "https://home/ResImg/shangchengimg/ul4.png",
        },
      ],

      chucunshuliangList: [
        {
          name: "",
          num: "",
          widthVar: "",
        },
        {
          name: "",
          num: "",
          widthVar: "",
        },
        {
          name: "",
          num: "",
          widthVar: "",
        },
      ],
      chart5: null,
      chart6: null,
      chart7: null,
      logisticsInd: {},
      sunhangLog: [],
      warehousePro: [],
      loaded: false,
    };
  },
  components: {
    Head,
  },

  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    setInterval(() => {
      this.randomNum = Math.floor(Math.random() * 3);
    }, 3000);
    this.loaded = true;
    this.getlogisticsInd();
    this.getsunhangLog();
    this.getwarehousePro();
    window.addEventListener("resize", () => {
      this.chart5.resize();
      this.chart6.resize();
      this.chart7.resize();
    });
  },

  methods: {
    getData() {
      axios
        .get("/10zhscd.json", {
          params: {
            // timestamp:new Date().getTime()
            random: Math.random(),
          },
        })
        .then((res) => {
          let dataObject = res.data;
          let jsonString = JSON.stringify(dataObject);
          let jsonObject = JSON.parse(jsonString);
          this.headTitle = jsonObject.bigTitle;
          this.chucunshuliangList = jsonObject.dailyRan;
          console.log("jsonObject=========>", jsonObject);
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    async getlogisticsInd() {
      const res = await axios.get("/10zhscd.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.logisticsInd = jsonObject.logisticsInd;

      console.log(this.logisticsInd.data);
      this.init5();
    },

    init5() {
      console.log(this.logisticsInd.data);
      this.chart5 = Echarts.init(this.$refs.chart5);
      let option = {
        title: {
          // text: "单位为:万件",
          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #FD9D20",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(20),
          right: nowSize(40),
          bottom: nowSize(10),
          top: nowSize(20),
          containLabel: true,
        },

        // legend: {
        // 	x: 'right',
        // 	y: 'top',
        // 	icon:'rect',
        // 	itemWidth:nowSize(14),
        // 	itemHeight:nowSize(14),
        // 	textStyle: {
        // 		color: '#fff',
        // 		fontSize: nowSize(18)
        // 	},
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 6000,
            // interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(18),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "line",
            // data: [2000,2700,3000,2600,1800,1500,2100,3600,4100,2500,2000,3500],
            data: this.logisticsInd.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#FD9D20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#FD9D20", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(235,157,32,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(235,157,32,.6)",
                    },
                  ]),
                },
              },
            },
          },
        ],
      };
      this.chart5.setOption(option, true);

      this.tootipTimer5 && this.tootipTimer2.clearLoop();
      this.tootipTimer5 = 0;
      this.tootipTimer5 = loopShowTooltip(this.chart5, option, {});
    },
    async getsunhangLog() {
      const res = await axios.get("/10zhscd.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);

      this.sunhangLog = jsonObject.sunhangLog;
      this.init6();
    },

    init6() {
      this.chart6 = Echarts.init(this.$refs.chart6);
      let chartData = [];
      chartData = this.sunhangLog;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|支付金额占比}\n\n{b|" + "共1200件商品}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(16),
                padding: [nowSize(-10), 0, nowSize(10), nowSize(92)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              },
              b: {
                fontSize: nowSize(16),
                padding: [nowSize(-10), 0, 0, nowSize(90)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "Source Han Sans CN-Regular, Source Han Sans CN",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          padding: [0, nowSize(105), 0, 0],
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{percent|" + p + "%}"; //+ "{value|" + value + "万个" +"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(255,233,156)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["72%", "85%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["66%", "68%"],
            center: ["25%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart6.setOption(option, true);

      this.tootipTimer6 && this.tootipTimer6.clearLoop();
      this.tootipTimer6 = 0;
      this.tootipTimer6 = loopShowTooltip(this.chart6, option, {});
    },
    async getwarehousePro() {
      const res = await axios.get("/10zhscd.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.warehousePro = jsonObject.warehousePro;
      this.init7();
      console.log(this.warehousePro, "this.warehousePro");
    },

    init7() {
      this.chart7 = Echarts.init(this.$refs.chart7);
      let chartData = [];
      chartData = this.warehousePro;

      let option = {
        // title: {
        //   // text: "单位为:万件",
        //   right: nowSize(10),
        //   top: nowSize(1),
        //   textStyle: {
        //     fontSize: nowSize(22),
        //     color: "#fff",
        //   },
        // },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #0045D1",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          data: ["分享访问量", "商品浏览量", "商品访客数"],
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(20),
          right: nowSize(40),
          bottom: nowSize(10),
          top: nowSize(20),
          containLabel: true,
        },

        // legend: {
        // 	x: 'right',
        // 	y: 'top',
        // 	icon:'rect',
        // 	itemWidth:nowSize(14),
        // 	itemHeight:nowSize(14),
        // 	textStyle: {
        // 		color: '#fff',
        // 		fontSize: nowSize(18)
        // 	},
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 6000,
            // interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(18),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            name: "分享访问量",
            type: "line",
            data: [149, 49, 195, 260, 255, 250, 170, 80, 198, 190, 149, 49],
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#0045D1", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#0045D1", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(45,244,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,69,209,.6)",
                    },
                  ]),
                },
              },
            },
          },
          {
            name: "商品浏览量",
            type: "line",
            data: [80, 29, 125, 220, 215, 220, 130, 40, 158, 150, 109, 9],
            // data: chartData.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#2DF4FF", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#2DF4FF", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(45,244,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(45,244,255,.6)",
                    },
                  ]),
                },
              },
            },
          },
          {
            name: "商品访客数",
            type: "line",
            data: [50, 9, 135, 200, 195, 200, 110, 20, 138, 130, 99, 2],
            // data: chartData.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#D2FFF4", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#D2FFF4", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(210,255,244,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(210,255,244,.6)",
                    },
                  ]),
                },
              },
            },
          },
        ],
      };
      this.chart7.setOption(option, true);

      this.tootipTimer7 && this.tootipTimer7.clearLoop();
      this.tootipTimer7 = 0;
      this.tootipTimer7 = loopShowTooltip(this.chart7, option, {});
    },
  },
};
</script>
  
  <style scoped>
.page {
  background: url(https://home/ResImg/shangchengimg/dsbj.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

.mod1 {
  margin-bottom: 1%;
}

.mod4 {
  height: 33%;
  margin-bottom: 1%;
}
.mod5 {
  height: 28%;
}

.mod1-b .b-smtit {
  margin-bottom: 0;
}

.mod1-b .chart {
  margin-top: 5%;
  height: calc(95% - 10px);
  flex: 1;
}

.mod1-b .grp {
  margin-top: 40px;
}
.mod1-b .grp .num {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
}
.mod1-b .grp .fz {
  font-size: 18px;
  color: #fff;
}
.mod1-b .tip {
  font-size: 20px;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mod1-b .tip .star {
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.mod2-b {
  margin-top: 40px;
}
.mod1-b .tip .wz {
  font-size: 20px;
  color: red;
  margin-left: 5px;
}

.mod2-b .col {
  width: 100%;
  height: 100%;
}

.mod2-b .chart {
  /* height: calc(100% - 100px); */
  height: calc(85% - 10px);
  flex: 1;
}

.mod2-bot {
  position: absolute;
  top: 60px;
  width: 100%;
}

.mod2-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod2-map .item {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 48, 104, 0.48);
  position: absolute;
  opacity: 0;
}
.mod2-map .item .txt {
  font-size: 30px;
  color: #45f5ff;
  line-height: 50px;
  padding-top: 7px;
}
.mod2-map .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2-map .item .tit {
  color: #fff;
}
.mod2-map .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2-map .item1 {
  right: 15%;
  top: 20%;
}
.mod2-map .item2 {
  left: 10%;
  top: 30%;
}
.mod2-map .item3 {
  right: 20%;
  top: 40%;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

.mod4-b {
  display: flex;
  justify-content: space-between;
}
.mod4-b .b-smtit {
  margin-bottom: 0;
}
.mod4-b .col {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 48%;
}

.mod4-b .chart {
  height: calc(95% - 10px);
  flex: 1;
}
.mod4-b .bar-list .row {
  margin-bottom: 15px;
  padding: 15px;
}
.mod4-b .bar-list .row .num {
  padding: 10px;
  font-size: 35px;
}

.mod5-sl {
  height: 100%;
}
.mod5-sl .chart {
  height: 100%;
}

.con-c {
  position: relative;
}

.cen-top.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 5%;
}

.cm {
  color: #ffd105;
}
.cy {
  color: #015aff;
}
.tuiul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.u_list {
  display: flex;
  align-items: center;
  width: 30%;
  margin-bottom: 33px;
}
.u_list img {
  width: 120px;
  height: 120px;
}
.u_list .u_text {
  font-size: 20px;
  color: #fff;
}
.u_list .u_text .visitors {
  margin-left: 20px;
}
.u_list .u_text .tex_box {
  font-size: 24px;
  color: #489ffe;
  margin-top: 14px;
  font-weight: 700;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
}
.u_list .u_text .tex_box span {
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
}
.u_list .u_text .ffa {
  color: #90ffad;
}
.mo_ul {
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 52px;
  overflow: hidden;
  height: 250px;
}
.mo_ul li {
  width: 96%;
  background: url(https://home/ResImg/shangchengimg/xiantu.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  height: 42px;
  margin-bottom: 12px;
}
.mo_ul li .u_lef {
  margin-left: 38px;
  font-size: 24px;
  padding: 0 10px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  color: #ffffff;
  line-height: 42px;

  position: relative;
}
.mo_ul li .u_lef span {
  position: relative;
  z-index: 2;
}
.mo_ul li .u_lef img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.mo_ul li .u_cen {
  font-size: 22px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #ffffff;
  line-height: 42px;
  width: 50%;
  padding-left: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mo_ul li .u_rig {
  font-size: 22px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
  margin-left: 150px;
}
.bott_ul {
  display: flex;
  padding-left: 20px;
  margin-top: 40px;
}
.bott_ul ul {
  width: 46%;
}
.charta {
  flex: 1;
}
.bott_ul ul li {
  display: flex;
  text-align: center;
  margin-bottom: 7px;
}
.bott_ul ul li .le_l {
  width: 77px;
}
.bott_ul ul li .le_r {
  flex: 1;
}
.bott_ul ul li {
  font-size: 22px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  background: url(https://home/ResImg/shangchengimg/ts_2.png) no-repeat;
  background-size: 100% 100%;
}
.bott_ul ul li:nth-child(1) {
  font-size: 22px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
  background: url(https://home/ResImg/shangchengimg/ts_1.png) no-repeat;
  background-size: 100% 100%;
}
.bott_ul ul li:nth-child(2) .le_r::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c03bda;
  margin-right: 11px;
}
.bott_ul ul li:nth-child(3) .le_r::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #45f5ff;
  margin-right: 11px;
}
.bott_ul ul li:nth-child(4) .le_r::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffe99c;
  margin-right: 11px;
}
.mod-cen {
  position: relative;
  height: 100%;
}

.jebox {
  display: flex;
  align-items: end;
  margin-top: 14px;
}
.jebox .text {
  font-size: 24px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 10px;
  line-height: 24px;
}

.jebox .rig_box .miaso {
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.jebox .rig_box .spans {
  font-size: 24px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  color: #ffffff;
  background: url(https://home/ResImg/shangchengimg/bsnub.png) no-repeat;
  background-size: 100% 100%;
  letter-spacing: 10px;
  width: 250px;
  display: block;
  text-align: center;
  margin-top: 5px;
}
.jebox .rig_box .miaso span:nth-child(1) {
  padding-left: 13px;
}
.jebox .rig_box .miaso span:nth-child(2) {
  padding-left: 7px;
}
.jebox .rig_box .miaso span:nth-child(3) {
  padding-left: 5px;
}
.jebox .rig_box .miaso span:nth-child(4) {
  padding-left: 4px;
}
.jebox .rig_box .miaso span:nth-child(5) {
  padding-left: 5px;
}
.jebox .rig_box .miaso span:nth-child(6) {
  padding-left: 12px;
}
.bot_ul {
  display: flex;
  justify-content: center;
  width: 100%;
}
.bot_ul li {
  padding: 15px;
  width: 380px;
  height: 134px;
  background: url(https://home/ResImg/shangchengimg/yngj.png) no-repeat;
  background-size: 100% 100%;
  margin: 0 18px;
}
.cen_box {
  margin-top: 38px;
}
.cen_box .box_lef {
  font-size: 24px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 46px;
}
.cen_box .box_rig {
  font-size: 24px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  color: rgba(69, 245, 255, 0.8392);
}
.bot_ul li:nth-child(1) .box_rig {
  letter-spacing: 5px;
}
.bar-list .font {
  justify-content: space-between;
}
.smrig {
  display: flex;
  align-items: flex-end;
  margin-left: 26px;
}
.smrig img {
  width: 16px;
  height: 22px;
}
.smrig span {
  font-size: 15px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #45f5ff;
  margin-left: 8px;
}
.bar-list .progress i {
  background: linear-gradient(270deg, #43f0fb 0%, #044b75 100%);
}
.mam2 {
  margin-top: 0;
}
.mam2 .u_list {
  width: 22%;
  margin-bottom: 22px;
}
.mam2 .tuiul {
  margin-bottom: 0;
}
</style>
  